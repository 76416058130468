import { Button } from "../ui/button";

const statusLabels: { [key: number]: { title: string; description: string } } =
  {
    404: {
      title: "お探しのページが見つかりませんでした。",
      description:
        "お探しのページは一時的にアクセスができない状態にあるか、移動もしくは削除された可能性があります。",
    },
    500: {
      title: "エラーが発生しました。",
      description: "しばらくしてから再度お試しください。",
    },
  };

export default function ErrorFeedback({
  status,
  statusText,
}: {
  status: number;
  statusText?: string;
}) {
  return (
    <main className="grid min-h-full w-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-3xl font-bold text-attention">{status}</p>
        <h1 className="mt-4 text-xl font-bold">
          {statusLabels[status] ? statusLabels[status].title : statusText}
        </h1>
        {statusLabels[status] && (
          <p className="mt-6 text-sm font-bold text-gray-5">
            {statusLabels[status].description}
          </p>
        )}
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a href="/">
            <Button>トップに戻る</Button>
          </a>
        </div>
      </div>
    </main>
  );
}
